<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="visiblepay"
      width="500px"
      :before-close="handleClose"
      center
    >
      <div class="content">
        <div v-if="(chanetype && !suretype&&!resetpsd)||suretype">
          <PassWord
            ref="PassWordInput"
            :psd.sync="PassWord"
            class="password-box"
          />
          <p v-if="tip" style="color: red">{{ tip }}</p>
        </div>
        <div v-if="resetpsd" class="22222">
          <span>设置新密码</span>
          <PassWord
            ref="PassWordInput1"
            :psd.sync="confirmPasswordf"
            class="password-box"
          />
           <p v-if="tip1" style="color: red">{{ tip1 }}</p>
          <span>重新输入密码</span>
          <PassWord
            ref="PassWordInput2"
            :psd.sync="confirmPassword"
            class="password-box"
          />
          <p v-if="tip" style="color: red">{{ tip }}</p>
        </div>
        <span v-if="suretype">设置六位数字作为新的密码</span>
        <div v-if=" !chanetype && !suretype" >
          <el-form
            label-position="left"
            size="small"
            label-width="80px"
            :model="form"
          >
            <el-form-item label="手机号">
              <span>{{ form.phone }}</span>
            </el-form-item>
            <el-form-item label="验证码">
              <el-input size="small" v-model="form.code" maxlength="6">
                <template slot="append"
                  ><el-button
                    size="small"
                    :disabled="codedis"
                    @click="getCode"
                    type="primary"
                    >{{
                      codedis ? "已发送" + codemes + "s" : codemes
                    }}</el-button
                  ></template
                >
              </el-input>
            </el-form-item>
            <p v-if="tip1" style="color: red">{{ tip1 }}</p>
          </el-form>
        </div>
        <div v-if="!suretype&&!resetpsd" class="right">
          <el-button type="text" @click="changetype">{{
            chanetype ? "忘记密码" : "使用旧密码更换"
          }}</el-button>
        </div>
      </div>
      <div class="foot" v-if="!chanetype">
        <el-button size="small" plain @click="handleClose()">取消</el-button
        ><el-button size="small" plain @click="nextToset">{{suretype?'确定':'下一步'}}</el-button>
      </div>
      <div class="foot" v-if="resetpsd">
        <el-button size="small" plain @click="handleClose()">取消</el-button
        ><el-button size="small" plain @click="resetpsdsubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import md5 from "js-md5";
import { getsendSmsCode,updatePwd, } from "@/api/home.js";
import { setPwdd, } from "@/api/userCenters.js";

export default {
  components: {
    PassWord: () => import("./password.vue"),
  },
  props: {
    visibleshow: {
      type: Boolean,
      default: false,
    },
    othertype: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    visibleshow(val) {
      this.visiblepay = val;
    },
    'PassWord.length'(val){
      console.log(val)
      if(val == 6 && !this.suretype) {
        this.title = '重置密码'
        this.resetpsd = true
      }
    }
  },
  data() {
    return {
      codemes: "获取验证码",
      codedis: false,
      form: {
        phone: window.localStorage.getItem("phone"),
        code:''
      },
      chanetype: true,
      title: "输入原密码",
      visiblepay: false,
      PassWord: "",
      tip: "",
      tip1: "",
      timer: 60,
      suretype: false,
      resetpsd: false,
      confirmPassword:'',
      confirmPasswordf:''
    };
  },
  methods: {
    submiteveryThing(foo) {
      const api = this.othertype?updatePwd:setPwdd
      let obj = this.othertype?
      {
        mobile: foo?window.localStorage.getItem("phone"):'',
        password: md5(this.PassWord),
        pwd: md5(this.confirmPassword),
        rePwd: md5(this.confirmPasswordf),
        verifyCode: this.form.code
      }:{
        password: md5(this.confirmPasswordf),
        confirmPassword: md5(this.confirmPassword),
        oldPassword:md5(this.PassWord),
        type: 2,
        operateType: 2,
        smsCode: this.form.code,
        telephone: foo?window.localStorage.getItem("phone"):''
      }
      api(obj)
      .then(res=>{
        console.log(res);
        if (res.code == 0) {
          this.$YTmsg("修改成功", "success");
          this.handleClose()
        } else {
          this.$YTmsg(res.message, "error");
          this.handleClose()    
        }
      })
    },
    changetype() {
      this.clearpassworld();
      this.$nextTick((e) => {
        this.chanetype = !this.chanetype;
        this.title = this.chanetype?'输入原密码':'忘记密码'
      });
      console.log(this.PassWord, "PassWord");
    },
    nextToset() {
      // debugger
      if(!this.suretype) {
        if(this.form.code.length<6) return this.tip1 = '请输入六位验证码'
        this.suretype = true
        this.title = "设置密码"
      } else {
      // 调用接口
        this.submiteveryThing(true)
      }
    },
    resetpsdsubmit() {
      if (this.confirmPasswordf.length < 6) {
        return this.tip1 = '请输入六位密码'
      } else {
        this.tip1 = ''
      }
      if (this.confirmPassword.length < 6) {
        return this.tip = '请输入六位密码'
      } else {
        this.tip = ''
      }
      if (this.confirmPassword!==this.confirmPasswordf) {
        return this.tip = '两次密码输入不一致'
      } else {
        this.tip = ''
      }
      // 调用接口
      this.submiteveryThing(false)
    },
    // 关闭重置数据
    clearpassworld() {
      this.title = '输入原密码'
      this.resetpsd = false
      this.suretype = false
      this.form.code = ''
      this.tip = ''
      this.tip1 = ''
      this.$refs.PassWordInput ? (this.$refs.PassWordInput.msg = "") : false;
      this.$refs.PassWordInput1 ? (this.$refs.PassWordInput1.msg = "") : false;
      this.$refs.PassWordInput2 ? (this.$refs.PassWordInput2.msg = "") : false;
    },
    handleClose() {
      this.chanetype = true
      this.clearpassworld();
      this.$emit("update:visibleshow", false);
    },
    async getCode() {
      const res = await getsendSmsCode({ phone: this.form.phone });
      if (res.code && res.code != 0) {
        return this.$YTmsg(res.message || "验证码发送失败", "error");
      }
      this.$YTmsg("验证码已发送,请注意查收", "success");
      if (this.codedis) return;
      this.codedis = true;
      this.codemes = 60;
      let auth_timer = setInterval(() => {
        this.codemes--; //递减时间
        if (this.codemes <= 0) {
          this.codedis = false;
          this.codemes = "获取验证码";
          clearInterval(auth_timer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item {
  // margin: 0;
  width: 330px;
}
// ::v-deep.el-form-item__content {
//   width: 330px !important;
// }
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 180px;
  .right {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
}
.foot {
  display: flex;
  justify-content: space-around
}
</style>